import { data } from "jquery";

export const getCover = (photos) => {
    if(photos?.length > 0){
        if(photos.find(photo => photo.is_front_cover)){
            return photos.find(photo => photo.is_front_cover);
        }
        else{
            return photos[0];
        }
    }
    return {original:"https://mediahaus.com.ar/noimage.jpg"}
}

export const getPreviewSlider = (photos) => {
    let array=[];
    if(photos){
        if(photos.length >= 5){
            array = photos.slice(0,5)

        }
        array[0] = getCover(photos)
    }
    return array
}

export const getPhotos = (data) => {
    if(data?.photos?.length > 0){
        return data.photos.filter(photo => !photo.is_blueprint)
    }
    return [{original:"https://mediahaus.com.ar/noimage.jpg"}]
} 

export const getDescription = (data,characters,notData = false) => {
    if(data.description ){
        if(data.description.length === 0)  return 'Sin descripción'
        const description = data.description.replaceAll("&nbsp;","").slice(0,characters).split("\n")
        return description
    }
    return ['Sin descripción']
}

export const descriptionIsNull = (data) => {
    if(data.description){
        if(data.description.length === 0)  return true
    }
    return false
}

export const getBrText = (text) => {
    const description = text.split("\n")
    return description
}

export const existDevelopment = (developments) => {
    if(developments?.length > 0){
        return true
    }
    return false
}

export const existOperation = (operation,filters) => {
    switch(operation){
        case 'venta':
            if(filters?.objects?.operation_types.find(operation => operation.operation_type === 1)) 
                return true
            break;
        case 'alquiler':
            if(filters?.objects?.operation_types.find(operation => operation.operation_type === 2))
                return true
            break;
    }
    return false
}

export const getMessageWhatsapp = (development) => {
    if(development?.name){
      return 'Quería consultar sobre el emprendimiento ' + development?.name
    }
    else {
      return "¡Hola!"
    }
}

export const existArray = (array) => {
    if(array.find(array => array === '')){
        return false
    }
    return true;
}


export const getPhonesText = (phones) => {
    let text = ''
    if(phones){
        phones.forEach((phone,index) => {
            text += phone + (index === phones.length - 1 ? '' : ' / ')
        })
    }
    return text
}

export const getFile = (file) => {
    let res    =   { name:"", ext:"", url:"" }
    res.name   =   file.file.split("/").at(-1).split(".")[0];
    res.ext    =   file.file.split("/").at(-1).split(".")[1];
    res.url    =   file.file;
    return res
}

export const makeShareUrl = ({env='staging',CLIENTID=0,novelty={},development={},property={},href=""}) => {
    const domain = 'https://share.mediacore.app/'
    
    const operation = property?.id ? href.split("/propiedad/")[1].split("/")[0].charAt(0).toUpperCase() + href.split("/propiedad/")[1].split("/")[0].slice(1) : ''
  
    const formateMessage = (str) => {
      return encodeURIComponent(str).replace(/[!'()]/g, escape).replace(/\*/g, "%2A");
    } 
  
    if(novelty?.id){
      return (`${domain}${CLIENTID}/novedades/${novelty.id}?url=${formateMessage(href)}`)
    }
    if(development?.id){
      return `${domain}${CLIENTID}/emprendimientos/${development.id}?url=${formateMessage(href)}`
    }
    if(property?.id){
      return `${domain}${CLIENTID}/propiedades/${property.id}?url=${formateMessage(href)}&operation=${formateMessage(operation)}`
    }
    return ''
  }

export const getQuality = photo => {
    return photo?.image
  }

export const getVideos = data => {
    if (data?.videos) {
      const list_videos = data.videos.filter(
        video => video.provider === "youtube" || video.provider === "vimeo"
      )
      return list_videos
    }
    return []
  }