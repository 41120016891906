import { useLocation } from "@reach/router"
import * as React from "react"
import { useEffect } from "react"
import { connect } from "react-redux"
import AlertTop from './Global/Modules/MediaPush/AlertTop'
import FlapButton from './Global/Modules/MediaPush/FlapButton'
import CounterPopup from "./Global/Modules/MediaPush/CounterPopup"
import SquarePop from './Global/Modules/MediaPush/SquarePop'
import {getActionByPage, getActionByType, getCodeFromType, getSectionFromCode, getTypeFromCode} from '../helpers/helper.actions'
import { useGetPushQuery } from "../redux/mediacore/push"


const Modules = () => {
  const {pathname} = useLocation()

  const alertObj = {
    section: "nosotros",
    type: "DESTAQUE TOP",
    url_destiny: "destino",
    title: "titulo",
    text: {
        text: "texto",
        text_band: "banda",
        text_button: "boton"
    },
    date_start: "2022-10-10T17:38:36.524669Z",
    date_end: "2022-10-11T00:00:00.000000Z",
    eventDate: `${new Date().getMonth() + 2}/${new Date().getDay() + 2}/${new Date().getFullYear()}`
  }

  const { data: allPushData, isLoading: loading } = useGetPushQuery(6);

  return !loading && (
    <>
      {getActionByType(allPushData,getCodeFromType('Solapa Flotante')).map((action => (
        <FlapButton 
        name={action.name}
        linkTo={action.texts.text_URL}
        utmValue={action.texts.text_utm}
        linkTxt={action.texts.text_limited}
        alignment={action.alignment}
        type={getTypeFromCode(action.type)}
        showOn={getSectionFromCode(action.section)}/>
      )))}
      {getActionByType(allPushData,getCodeFromType('PopUp Timer')).map((action => (
        <CounterPopup 
          name={action.name}
          linkTo={action.texts.text_URL} 
          linkTxt={action.texts.text_button} 
          title={action.title}
          showOn={getSectionFromCode(action.section)}
          band={action.texts.text_band} 
          text={action.texts.text}
          utmValue={action.texts.text_utm}
          type={getTypeFromCode(action.type)}
          eventDate={action.date_start}/> 
      )))}
      {getActionByType(allPushData,getCodeFromType('PopUp Vertical')).map((action => (
        <SquarePop
        name={action.name}
        type={getTypeFromCode(action.type)}
        align={action.alignment} 
        flat={false} 
        linkTo={action.url_destiny}
        image={action.file}
        utmValue={action.text_utm}
        small_type={action.type}
        showOn={getSectionFromCode(action.section)} /> 
      )))}
      {getActionByType(allPushData,getCodeFromType('PopUp Cuadrado')).map((action => (
        <SquarePop
        name={action.name}
        type={getTypeFromCode(action.type)}
        align={""} 
        flat={false} 
        linkTo={action.url_destiny}
        image={action.file}
        utmValue={action.text_utm}
        showOn={getSectionFromCode(action.section)} /> 
        )))}
      {getActionByType(allPushData,getCodeFromType('PopUp Panoramico')).map((action => (
        <SquarePop
        name={action.name}
        type={getTypeFromCode(action.type)}
        align={""} 
        flat={true} 
        linkTo={action.url_destiny}
        image={action.file}
        utmValue={action.text_utm}
        showOn={getSectionFromCode(action.section)} /> 
      )))}
      {getActionByType(allPushData,getCodeFromType('Barra Encabezado Timer')).map((action => (
        <AlertTop 
        name={action.name}
        linkTo={action.texts.text_URL}
        linkTxt={action.texts.text_button}
        textAlert={action.texts?.text}
        showOn={getSectionFromCode(action.section)}
        type={getTypeFromCode(action.type)}
        utmValue={action.texts.text_utm}
        eventDate={action.date_start}/>
      )))}
      {getActionByType(allPushData,getCodeFromType('Barra Encabezado')).map((action => (
        <AlertTop 
        name={action.name}
        linkTo={action.texts.text_URL}
        linkTxt={action.texts.text_button}
        textAlert={action.texts?.text}
        type={getTypeFromCode(action.type)}
        utmValue={action.texts.text_utm}
        showOn={getSectionFromCode(action.section)}/>
      )))}
    </>
  )
}

export default connect(state => ({
  actions:state.actions.actions
}),null)(Modules);