import React, {useEffect} from "react";
import LOGO_FOOTER from "../images/svg/laura-farias-logo.svg";
import { Link } from 'gatsby';
import { graphql, useStaticQuery } from 'gatsby';
import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { connect } from "react-redux";
import toast, { Toaster } from 'react-hot-toast';
import SVG from "../images/svg/broker-access.svg"


import {existOperation,existDevelopment, getMessageWhatsapp, getPhonesText} from '../helpers/helper.rendering'

import {newSletterTokko,updateFields} from '../redux/contactDucks'

import { Helmet } from "react-helmet"
import { GET_INVESTOR_ACTION,GET_CONFIGURATIONS_ACTION } from "../redux/settingsDucks";
import { useGetConfigurationsQuery } from "../redux/mediacore/configuration";
import { useGetDevelopmentsQuery } from "../redux/middlewareTokkoApi/developments";
import { useGetTotalFiltersQuery } from "../redux/originalTokkoApi/properties";

const Footer = ({filters,development,dispatch}) => {

  const [open,setOpen] = useState(false)

  const { realEstate } = useStaticQuery(graphql`
    query {
        realEstate {
            global_email
            fiscal_data
            bot_cliengo
            global_whatsapp
            global_phone
            global_broker_id
            keys{
                captcha
                tokko
            }
            social{
              facebook
              instagram
            }
            branch_office{
              name
              address
              city
              region
              broker_id
              contact {
                phone
                mail
                whatsapp
              }
            }
            sections {
                footer {
                    rows {
                    title_contact
                    button_contact {
                      value
                      link
                    }
                    title_office
                    title_social
                    title_newsletter
                  }
                }
            }
        }
  }`)

  const [disabled,setDisabled] = useState(true)
  const [datos, setDatos] = useState({
    email: "",
  });

  const handleInputChange = (e) => {
      setDatos({
        ...datos,
        [e.target.name]: e.target.value,
      });
  };

  const sendForm = (e) => {
    e.preventDefault();
    dispatch(newSletterTokko())
    document.getElementById("formNewsletter").reset();
    return toast.success("Te suscribiste a nuestro newsletter correctamente.") 
  }

  // Función que devuelve el año actual
  const getCurrentYear = () => {
    const date = new Date().getFullYear()
    return date;
  }
    
  useEffect(() => {
    const update = () => {
        dispatch(updateFields(datos));
    };
    update();
    if(!realEstate.keys.captcha){
      setDisabled(false)
    }
    else{
        setDisabled(true)
    }
  }, [datos]);

  const { data: modules } = useGetConfigurationsQuery(6);
  const { data: developments } = useGetDevelopmentsQuery(realEstate.keys.tokko);
  const { data: allTokkoFilterData, isLoading: isLoadingAllTokkoFilters } = useGetTotalFiltersQuery({"API_KEY":realEstate.keys.tokko});

  const INVESTOR_CONFIGURATION = modules?.find(config => config.module === "INVESTOR")
  const OWNER_CONFIGURATION = modules?.find(config =>config.module === "OWNER")
  const configUrl = (url) => (url?.includes("http")) ? url : `https:${url}`;

    return(
      <div>
        <div className="sup-footer">
            <div className="container-fluid">
              <div className="fixed-bar position-fixed">
                  <>
                    <a href={"https://web.whatsapp.com/send?text=" + getMessageWhatsapp(development) + "&phone=+54" + realEstate.branch_office[0].contact.whatsapp.replaceAll("-",'')} target="_blank" className={"branch-btn contact-btn d-none d-lg-flex " + (open ? 'open' : ' ')}>{realEstate.branch_office[0].name}</a>
                    <a href={"https://web.whatsapp.com/send?text=" + getMessageWhatsapp(development) + "&phone=+54" + realEstate.branch_office[1].contact.whatsapp.replaceAll("-",'')} target="_blank" className={"branch-btn contact-btn d-none d-lg-flex " + (open ? 'open' : ' ')}>{realEstate.branch_office[1].name}</a>
                    <a href={"https://api.whatsapp.com/send?text=" + getMessageWhatsapp(development) + "&phone=+54" + realEstate.branch_office[0].contact.whatsapp.replaceAll("-",'')} target="_blank" className={"branch-btn contact-btn d-flex d-lg-none " + (open ? 'open' : ' ')}>{realEstate.branch_office[0].name}</a>
                    <a href={"https://api.whatsapp.com/send?text=" + getMessageWhatsapp(development) + "&phone=+54" + realEstate.branch_office[1].contact.whatsapp.replaceAll("-",'')} target="_blank" className={"branch-btn contact-btn d-flex d-lg-none " + (open ? 'open' : ' ')}>{realEstate.branch_office[1].name}</a>
                  </>
                  <a onClick={() => setOpen(!open)} className="icon-whatsapp-o contact-btn d-flex"></a>
              </div>
              <div className="row">
                <div className="col-12">
                    <div className="row">
                      <div className="col-lg-4 order-1">
                        <div className="item-contact row">
                            <h5 className="col-lg-12 col-4">{realEstate.sections.footer.rows.title_contact}</h5>
                            <div className="col-lg-12 col-8">
                              {/* <p className={"mb-2 " + (realEstate?.global_phone?.length === 0 ? 'd-none' : '')}>Teléfono {getPhonesText(realEstate?.global_phone)}</p> */}
                              <a target="_blank" className="hover-footer-link mb-2" href={"mailto:"+realEstate.global_email}>{realEstate.global_email}</a>
                              {/* {realEstate?.global_broker_id.map((broker,index) => (
                                <p className="mb-1">Matrícula {broker}</p>
                              ))} */}
                              
                              {/* <a target="_blank" className="hover-footer-link mb-5" href={"https://api.whatsapp.com/send?phone=+549" + (realEstate.whatsapp.replaceAll("-",""))}>Whatsapp {realEstate.whatsapp}</a> */}
                              <Link className="d-none d-lg-none btn btn-blue mt-5" to={realEstate.sections.footer.rows.button_contact.link}>{realEstate.sections.footer.rows.button_contact.value}</Link>

                              {/* <h5 className="d-block d-lg-block mt-5 mb-4 underline-title">Accesos privados</h5>
                              {investor?.id && <a className="btn btn-blue d-inline-flex align-items-center" target="_blank" href={"https://mediacore.app/sesion/inicio/" +investor.url_login}><i className="icon-broker-access"></i> {investor.text_cta}</a>} */}
                              {(INVESTOR_CONFIGURATION?.module || OWNER_CONFIGURATION?.module) ?
                                <div className="module-config-wrapper d-flex flex-column">
                                  <h5 className="mt-5 mb-4 underline-title">Accesos privados</h5>
                                  { INVESTOR_CONFIGURATION?.module 
                                    ? <a className="btn btn-blue d-inline-flex align-items-center" href={configUrl(INVESTOR_CONFIGURATION.url_login)} target="__blank">
                                        <i className="icon-broker-access"></i>
                                        <span className="text-uppercase">{INVESTOR_CONFIGURATION.text_cta || "INVERSORES"}</span>
                                      </a> : null }
                                  { OWNER_CONFIGURATION?.module 
                                    ? <a className="btn btn-blue d-inline-flex align-items-center mt-2" href={configUrl(OWNER_CONFIGURATION.url_login)} target="__blank">
                                        <i className="icon-house"></i>
                                        <span className="text-uppercase">{OWNER_CONFIGURATION.text_cta || "PROPIETARIOS"}</span>
                                      </a> : null }
                                  </div> : null }
                              {/* <Link className="btn btn-blue d-lg-inline-block d-none mt-5" to={realEstate.sections.footer.rows.button_contact.link}>{realEstate.sections.footer.rows.button_contact.value}</Link> */}
                            </div>
                        </div>
                      </div>
                      <div className="col-lg-4 order-2">
                        <div className="item-contact row d-none d-lg-block">
                            <h5  className="col-lg-12 col-4">{realEstate.sections.footer.rows.title_office}{realEstate.branch_office[0].name}</h5>
                            <div className="col-lg-12 col-8">
                              <p >{realEstate.branch_office[0].address}</p>
                              <p >{realEstate.branch_office[0].city}</p>
                              <p >{realEstate.branch_office[0].region}</p>
                              <p >Teléfono {realEstate.branch_office[0].contact.phone}</p>
                              <p >Whatsapp {realEstate.branch_office[0].contact.whatsapp}</p>
                              <p >Matrícula {realEstate.branch_office[0].broker_id}</p>
                            </div>
                        </div>
                      </div>
                      <div className="col-lg-4 pl-lg-4 order-3">
                        <div className="item-contact row mb0">
                            <h5  className="col-lg-12 col-4">{realEstate.sections.footer.rows.title_social}</h5>
                            <div className="col-lg-12 col-8">
                              <a target="_blank" href={realEstate.social.instagram} className="d-inline-block me-3"><i className="icon-instagram"></i></a>
                              <a target="_blank" href={realEstate.social.facebook} className="d-inline-block"><i className="icon-facebook"></i></a>
                            </div>
                        </div>
                      </div>
                    </div>
                </div>
                <div className="col-lg-4">
                  <div></div>
                </div>
                <div className="col-lg-4">
                  <div className="item-contact row d-flex d-lg-none">
                    <h5  className="col-lg-12 col-4">{realEstate.sections.footer.rows.title_office}{realEstate.branch_office[0].name}</h5>
                    <div className="col-lg-12 col-8">
                      <p >{realEstate.branch_office[0].address}</p>
                      <p >{realEstate.branch_office[0].city}</p>
                      <p >{realEstate.branch_office[0].region}</p>
                      <p >Teléfono {realEstate.branch_office[0].contact.phone}</p>
                      <p >Whatsapp {realEstate.branch_office[0].contact.whatsapp}</p>
                      <p >Matrícula {realEstate.branch_office[0].broker_id}</p>
                    </div>
                  </div>
                  <div className="item-contact row">
                        <h5  className="col-lg-12 col-4">{realEstate.sections.footer.rows.title_office}{realEstate.branch_office[1].name}</h5>
                        <div className="col-lg-12 col-8">
                          <p >{realEstate.branch_office[1].address}</p>
                          <p >{realEstate.branch_office[1].city}</p>
                          <p >{realEstate.branch_office[1].region}</p>
                          <p >Teléfono {realEstate.branch_office[1].contact.phone}</p>
                          <p >Whatsapp {realEstate.branch_office[1].contact.whatsapp}</p>
                          <p >Matrícula {realEstate.branch_office[1].broker_id}</p>
                        </div>
                    </div>
                </div>
                
                <div className="col-lg-4">
                    <div className="content-contact mt-auto">
                      <h4 >{realEstate.sections.footer.rows.title_newsletter}</h4>
                      <form onSubmit={sendForm} id="formNewsletter" >
                        <div className="d-flex mb-lg-3">
                            <input type="email" onChange={handleInputChange} name="email" required placeholder="Ingresá tu mail" className="mr-2" />
                            <button
                             disabled={disabled}
                            type="submit" className="submit icon-arrow-right"></button>
                        </div>
                        {
                            realEstate?.keys?.captcha ? 
                            <ReCAPTCHA
                            sitekey={realEstate?.keys?.captcha ? realEstate?.keys?.captcha : 0}
                            onChange ={() => setDisabled(false)}
                            /> 
                            : 
                            '' 
                        }
                      </form>
                    </div>
                </div>
              </div>
            </div>      
        </div>
        <footer>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-7 col-lg-3 d-flex d-lg-block align-items-start flex-column">
                      <div className="logo-footer">
                        <Link to={"/"}><LOGO_FOOTER /></Link>
                      </div>
                        <div className="d-block d-lg-none mt-auto">
                           {
                            realEstate?.fiscal_data ? 
                              <a className="qr d-flex justify-content-start" href={realEstate?.fiscal_data} target="_F960AFIPInfo"><img src="http://www.afip.gob.ar/images/f960/DATAWEB.jpg" border="0" /></a>
                            :
                              ''
                          }
                            <div className="d-flex align-items-start">
                                <p className="copy">
                                   Design by <a className="mh" href="https://mediahaus.com.ar">MediaHaus</a> - Powered by <a className="mc" href="https://mediacore.app/"> MediaCore</a> <br/> Todos los derechos reservados &reg; {getCurrentYear()}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-5 col-lg-6 offset-lg-3 d-lg-flex align-items-center justify-content-between">
                        <li><Link to="/">Home</Link></li>
                        {existDevelopment(developments) ? <li><Link to="/emprendimientos">Emprendimientos</Link></li>:''}
                        {existOperation("venta",allTokkoFilterData) ? <li><Link to="/venta">Venta</Link></li>:''}
                        {existOperation("alquiler",allTokkoFilterData) ?<li><Link to="/alquiler">Alquiler</Link></li>:''}
                        <li><Link to="/servicios">Servicios</Link></li>
                        <li><Link to="/nosotros">Nosotros</Link></li>
                        <li><Link className="mb-0" to="/contacto">Contacto</Link></li>
                    </div>
                </div>
                <div className="row mt-lg-3 py-lg-3 d-none d-lg-flex justify-content-between align-items-end">
                    <div className="col-lg-3 col-6 d-lg-flex align-items-start">
                        <p className="copy">
                          Design by <a className="mh" href="https://mediahaus.com.ar">MediaHaus</a> - Powered by <a className="mc" href="https://mediacore.app/"> MediaCore</a> <br/> Todos los derechos reservados &reg; {getCurrentYear()}
                        {/* Plataforma <a href="https://mediacore.app/"> MediaCore<span style={{fontSize: "0.5rem", verticalAlign: "super"}}>® </span> </a> by <a href="https://mediahaus.com.ar">MediaHaus<span style={{fontSize: "0.5rem", verticalAlign: "super"}}>® </span></a> {getCurrentYear()}. Todos los derechos reservados. */}       
                        </p>
                    </div>
                    <div className="col-lg-2">
                      {realEstate?.fiscal_data ?
                        <a className="qr d-flex justify-content-end" href="http://qr.afip.gob.ar/?qr=-0KJfM8nf9YTPdrzcTQTrg,," target="_F960AFIPInfo"><img src="http://www.afip.gob.ar/images/f960/DATAWEB.jpg" border="0" /></a>
                        :
                        ''
                      }
                    </div>
                </div>
            </div>
        </footer>
      </div>
    )
}

export default connect(state => ({
  filters:state.properties.totalListFilters,
  api_key:state.settings.keys.tokko,
  investor:state.settings.investor,
  modules: state.settings.modules,
  developments:state.developments.developments,
  development:state.developments.development
}),null)(Footer);