import { getPrices } from "./helper.properties"

//Funcion para listar/eliminar favoritos en localStorage
export const addFavorites = (id,type,inmobiliaria) =>{
  if(localStorage.getItem(type + "_favorito_" + inmobiliaria )){
      const storage = localStorage.getItem(type + "_favorito_" + inmobiliaria )
        var array_storage = storage.split("-")
        array_storage = array_storage.filter(element => element != "")
        if(array_storage.find(element => element === id.toString())){
            localStorage.setItem(type + "_favorito_" + inmobiliaria ,"")
            var array_new = (array_storage.filter(element => element != id.toString()))
            for (let index = 0; index < array_new.length; index++) {
                localStorage.setItem(type + "_favorito_" + inmobiliaria , (localStorage.getItem(type + "_favorito_" + inmobiliaria )  + array_new[index] + "-"))
            }
            return null
        }
        else{
            localStorage.setItem(type + "_favorito_" + inmobiliaria , (localStorage.getItem(type + "_favorito_" + inmobiliaria ) +  id + "-"))
        }
  }
  else{
      localStorage.setItem(type + "_favorito_" + inmobiliaria , id + "-")
  }
    return null
}

//Función para checkear un favorito
export const checkFavorite = (id,type,inmobiliaria) => {
  var storage = localStorage.getItem(type + "_favorito_" + inmobiliaria ).split("-") // Recupero los ids
  console.log(storage)
  storage = storage.filter(element => element !== "") // Elimino si hay algun espacio vacio
  if(storage.find(element => element?.toString() === id?.toString())){
    return true;
  }
  return false
  return false
}

//Funcion para traer todos los favoritos
export const getfavorites = (type,inmobiliaria) => {
  if(typeof window !== "undefined"){
    var storage = localStorage.getItem(type + "_favorito_" + inmobiliaria ) // Recupero los ids
    if(!storage){localStorage.setItem(type + "_favorito_" + inmobiliaria ,"")} // Si no hay nada en el localStorage lo inicializo
    storage = localStorage.getItem(type + "_favorito_" + inmobiliaria ).split("-") // Recupero los ids
    storage = storage.filter(element => element !== "") // Elimino si hay algun espacio vacio
    return storage //Retorno array de ids
  }
  return []
}

export const total_comparate = (properties) => {
  let max = -1;
  for (const property of properties) {
    if(property){
      if(parseInt(property?.total_surface) > max && parseInt(property?.total_surface) > 0){
        max = parseInt(property?.total_surface)
      }
    }
  }
  return max;
}

export const roofed_comparate = (properties) => {
  let max = -1;
  for (const property of properties) {
    if(property){
      if(parseInt(property?.roofed_surface) > max && parseInt(property?.roofed_surface) > 0){
        max = parseInt(property?.roofed_surface)
      }
    }
  }
  return max;
}

export const semiroofed_comparate = (properties) => {
  let max = -1;
  for (const property of properties) {
    if(property){
      if(parseInt(property?.semiroofed_surface) > max && parseInt(property?.semiroofed_surface) > 0){
        max = parseInt(property?.semiroofed_surface)
      }
    }
  }
  return max;
}

export const surface_comparate = (properties) => {
  let max = -1;
  for (const property of properties) {
    if(property){
      if(parseInt(property?.surface) > max && parseInt(property?.surface) > 0){
        max = parseInt(property?.surface)
      }
    }
  }
  return max;
}

export const prices_comparate = (type,properties) => {
  let min = 9999999999999;
  for (const property of properties) {
    if(property){
      if(getPrices(property).find(element => element.type.toLowerCase() === type)?.price < min){
        min = getPrices(property).find(element => element.type.toLowerCase() === type)?.price
      }
    }
  }
  return min;
}

export const environment_comparate = (properties) => {
  let max = -1;
  for (const property of properties) {
    if(property){
      if(property?.room_amount > max && property?.room_amount > 0){
        max = (property?.room_amount)
      }
    }
  }
  return max;
}

export const room_comparate = (properties) => {
  let max = -1;
  for (const property of properties) {
    if(property){
      if(property?.suite_amount > max && property?.suite_amount > 0 && property?.suite_amount !== undefined){
        max = (property?.suite_amount)
      }
    }
  }
  return max;
}
export const bathrooms_comparate = (properties) => {
  let max = -1;
  for (const property of properties) {
    if(property){
      if(property?.bathroom_amount > max && property?.bathroom_amount > 0 && property?.bathroom_amount !== undefined){
        max = (property?.bathroom_amount)
      }
    }
  }
  return max;
}

export const toilets_comparate = (properties) => {
  let max = -1;
  for (const property of properties) {
    if(property){
      if(property?.toilet_amount > max && property?.toilet_amount > 0 && property?.toilet_amount !== undefined){
        max = (property?.toilet_amount)
      }
    }
  }
  return max;
}

export const age_comparate = (properties) => {
  let minAge = 999;
  for (const property of properties) {
    if(property){
      if((property?.age) === 0){
        minAge = (property?.age)
        return minAge;
      }
      else if((property?.age < minAge && property?.age !== -1)){
        minAge = property?.age;
      }
    }
  }
  return minAge;
}

export const parking_comparate = (properties) => {
  let max = -1;
  for (const property of properties) {
    if(property){
      if((property?.parking_lot_amount) > max && property?.parking_lot_amount > 0){
        max = (property?.parking_lot_amount)
      }
    }
  }
  return max;
}


export const pool_comparate = (properties) => {
  let max = 0;
  for (const property of properties) {
    if(property){
      if((property?.parking_lot_amount) > max){
        max = (property?.parking_lot_amount)
      }
    }
  }
  return max;
}